.landinghead {
    background-color: #e7e5ec;
    text-align: center;
    padding: 50px 0 75px;
}
.landinghead h1 {
    font-size: 40px;
    margin: 0 0 15px;
}
.landinghead p {
    margin: 0;
    font-size: 18px;
}
.landinghead .landingheadcta a, .finalctacontent .landingheadcta a {
    background-color: var(--color1);
    color: var(--color2);
    transition: all 0.5s ease;
    padding: 12px 45px 15px;
    font-size: 18px;
}
.landinghead .landingheadcta, .finalctacontent .landingheadcta {
    margin: 30px 0 0;
}
.landinghead .landingheadcta a:hover, .finalctacontent .landingheadcta a:hover {
    color: var(--color2);
    background-color: var(--color4);
}
.employwellhead {
    text-align: center;
    margin: 0 0 40px;
}
.employwellcontent {
    box-shadow: 0px 0px 5px 4px #eaeaea;
    border-radius: 5px;
    padding: 15px;
    height: 100%;
    background-color: var(--color2);
}
.employwellcontent h3 {
    font-size: 23px;
    line-height: 1.3;
    margin: 0 0 12px;
}
.employwellcontent ul {
    margin: 0;
}
.employwellcontent ul li {
    font-size: 17px;
    margin: 0 0 9px;
    line-height: 1.5;
}
.employwell {
    padding: 0 0 60px;
}
.employwell.backgroundcolor {
    background-color: #e7e5ec;
    padding-top: 50px;
    margin-bottom: 50px;
}
.workshop.landingpage .retreatimg img {
    height: auto;
}
.finalcta {
    padding: 50px 0;
}
.finalctacontent {
    text-align: center;
    width: 70%;
    margin: auto;
    border: 1px solid #dcdcdc;
    padding: 35px 15px;
    box-shadow: 0px 0px 5px 4px #eaeaea;
    border-radius: 5px;
}
.finalctacontent p {
    margin: 0;
    font-size: 19px;
    font-style: italic;
    line-height: 1.5;
    font-weight: 500;
}